import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { parsePhoneNumber } from "libphonenumber-js";
import { AttributeContext } from "../../providers/AttributeProvider";
import { ItemContext } from "../../providers/ItemProvider";
import useCopy from "../../hooks/useCopy";
import {
  Relationships,
  Preferences,
  AttendancePreferences,
  AttributeCategories,
  PaymentMethods,
  Availability,
} from "@sussex/match-utils";

const Wrapper = styled.div`
  padding: 24px;
`;

const Bubble = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 16px;
  display: flex;
  gap: 24px;

  & + & {
    margin-top: 24px;
  }
`;

const Preference = styled.div`
  flex: 1;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  line-height: 1.5;
  padding-bottom: 10px;
  margin: 0;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.2;
  margin: 0;
  word-break: break-word;

  & + & {
    padding-top: 10px;
  }
`;

const ItemPanel = ({ item }) => {
  const { getAttributeLabel } = useContext(AttributeContext);
  const { decryptMessage } = useContext(ItemContext);
  const [message, setMessage] = useState(null);
  const [
    contactTitle,
    contactName,
    contactEmail,
    contactPhone,
    whoTitle,
    whoClient,
    whoLocation,
    relationshipMyselfText,
    relationshipChildText,
    relationshipCouplesText,
    howTitle,
    howInPerson,
    howOnline,
    howBoth,
    issuesTitle,
    paymentTitle,
    paymentCash,
    demographicsTitle,
    demographicsNoPreference,
    demographicsGender,
    demographicsSexuality,
    demographicsReligion,
    demographicsEthnicity,
    demographicsLanguage,
    availabilityTitle,
    availabilityMorning,
    availabilityAfternoon,
    availabilityEvening,
    availabilityWeekend,
    messageTitle,
  ] = useCopy([
    "client.matchDetails.contactTitle",
    "client.matchDetails.contactName",
    "client.matchDetails.contactEmail",
    "client.matchDetails.contactPhone",
    "client.matchDetails.whoTitle",
    "client.matchDetails.whoClient",
    "client.matchDetails.whoLocation",
    "client.matchDetails.self",
    "client.matchDetails.child",
    "client.matchDetails.couple",
    "client.matchDetails.howTitle",
    "client.matchDetails.howInPerson",
    "client.matchDetails.howOnline",
    "client.matchDetails.howBoth",
    "client.matchDetails.issuesTitle",
    "client.matchDetails.paymentTitle",
    "client.matchDetails.cash",
    "client.matchDetails.demographicsTitle",
    "client.matchDetails.demographicsNoPreference",
    "client.matchDetails.demographicsGender",
    "client.matchDetails.demographicsSexuality",
    "client.matchDetails.demographicsReligion",
    "client.matchDetails.demographicsEthnicity",
    "client.matchDetails.demographicsLanguage",
    "client.matchDetails.availability",
    "client.matchDetails.mornings",
    "client.matchDetails.afternoons",
    "client.matchDetails.evenings",
    "client.matchDetails.weekends",
    "client.matchDetails.messageTitle",
  ]);

  useEffect(() => {
    if (message) {
      return;
    }
    const decrypt = async () => {
      if (item.message) {
        const decrypted = await decryptMessage(item.message);
        setMessage(decrypted);
      }
    };
    decrypt();
  }, [item, message, decryptMessage]);

  const phone = parsePhoneNumber(item.phoneNumber);
  const formattedPhone =
    phone.country === item.location.country
      ? phone.formatNational()
      : phone.formatInternational();

  const getRelationship = () => {
    const relationship = item.preferences[Preferences.relationship];
    const otherRelationshipName =
      item.preferences[Preferences.otherRelationshipName];

    switch (relationship?.[0]?.value) {
      case Relationships.myself:
        return relationshipMyselfText;
      case Relationships.child:
        return relationshipChildText;
      case Relationships.couple:
        return relationshipCouplesText;
      case Relationships.else:
        return otherRelationshipName?.[0]?.value;
      default:
        return "";
    }
  };

  const getLocation = () => {
    let locality = item.location.locality;
    if (!locality) {
      locality = item.location.sublocality;
    }
    return `${locality}, ${item.location.areaLevel1}`;
  };

  const getAttendancePreference = () => {
    const attendancePreference = item.preferences[Preferences.attendance];

    if (attendancePreference?.length === 2) {
      return howBoth;
    }

    switch (attendancePreference?.[0]?.value) {
      case AttendancePreferences.online:
        return howOnline;
      case AttendancePreferences.inPerson:
        return howInPerson;
      default:
        return "";
    }
  };

  const getIssues = () => {
    const issues = item.preferences[Preferences.issues];
    const issueWriteIn = item.preferences[Preferences.issuesWriteIn];
    const issueKeys = issues ? issues.map(i => i.value) : [];
    const allIssues = issueKeys.map(i =>
      getAttributeLabel({ category: AttributeCategories.issues, id: i }),
    );
    if (issueWriteIn) {
      allIssues.push(issueWriteIn?.[0]?.value);
    }
    return allIssues;
  };

  const getPaymentMethods = () => {
    const paymentMethod = item.preferences[Preferences.paymentMethod];
    if (paymentMethod?.[0]?.value === PaymentMethods.cash) {
      return [paymentCash];
    }
    const insurances = item.preferences[Preferences.insurance];
    const insuranceWriteIn = item.preferences[Preferences.insuranceWriteIn];
    const insuranceKeys = insurances ? insurances.map(i => i.value) : [];
    const allInsurances = insuranceKeys.map(i =>
      getAttributeLabel({ category: AttributeCategories.insurance, id: i }),
    );
    if (insuranceWriteIn) {
      allInsurances.push(insuranceWriteIn?.[0]?.value);
    }
    if (paymentMethod?.[0]?.value === PaymentMethods.insurance) {
      return allInsurances;
    }
    return [paymentCash, ...allInsurances];
  };

  const getDemographics = () => {
    const getLabel = (category, item) =>
      item?.[0]?.value
        ? getAttributeLabel({
            category,
            id: item?.[0]?.value,
          })
        : demographicsNoPreference;

    const gender = item.preferences[Preferences.gender];
    const sexuality = item.preferences[Preferences.communitySexuality];
    const religion = item.preferences[Preferences.communityReligion];
    const ethnicity = item.preferences[Preferences.communityEthnicity];
    const language = item.preferences[Preferences.language];

    return [
      `${demographicsGender}: ${getLabel(AttributeCategories.gender, gender)}`,
      `${demographicsSexuality}: ${getLabel(
        AttributeCategories.communitySexuality,
        sexuality,
      )}`,
      `${demographicsReligion}: ${getLabel(
        AttributeCategories.communityReligion,
        religion,
      )}`,
      `${demographicsEthnicity}: ${getLabel(
        AttributeCategories.communityEthnicity,
        ethnicity,
      )}`,
      `${demographicsLanguage}: ${getLabel(
        AttributeCategories.language,
        language,
      )}`,
    ];
  };

  const getAvailability = () => {
    const availability = item.preferences[Preferences.availability];
    const availabilityLabels = {
      [Availability.mornings]: availabilityMorning,
      [Availability.afternoons]: availabilityAfternoon,
      [Availability.evenings]: availabilityEvening,
      [Availability.weekends]: availabilityWeekend,
    };
    return availability
      ? availability.map(i => availabilityLabels[i.value])
      : [];
  };

  return (
    <Wrapper>
      <Bubble>
        <Preference>
          <Title>{contactTitle}</Title>
          <Text>
            {contactName}: {item.name}
          </Text>
          <Text>
            {contactEmail}: {item.email}
          </Text>
          <Text>
            {contactPhone}: {formattedPhone}
          </Text>
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{whoTitle}</Title>
          <Text>
            {whoClient}: {getRelationship()}
          </Text>
          <Text>
            {whoLocation}: {getLocation()}
          </Text>
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{howTitle}</Title>
          <Text>{getAttendancePreference()}</Text>
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{issuesTitle}</Title>
          {getIssues().map((i, k) => (
            <Text key={k}>{i}</Text>
          ))}
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{paymentTitle}</Title>
          {getPaymentMethods().map((i, k) => (
            <Text key={k}>{i}</Text>
          ))}
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{demographicsTitle}</Title>
          {getDemographics().map((i, k) => (
            <Text key={k}>{i}</Text>
          ))}
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{availabilityTitle}</Title>
          {getAvailability().map((i, k) => (
            <Text key={k}>{i}</Text>
          ))}
        </Preference>
      </Bubble>
      <Bubble>
        <Preference>
          <Title>{messageTitle}</Title>
          {message && <Text>{message}</Text>}
        </Preference>
      </Bubble>
    </Wrapper>
  );
};

export default ItemPanel;
