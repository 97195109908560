import { useState, useEffect, useContext, createContext, useRef } from "react";
import themis from "wasm-themis";
import { CopyContext } from "../CopyProvider";
import { UserContext } from "../UserProvider";
import { AttributeContext } from "../AttributeProvider";
import { ValidAttributeCategories } from "@sussex/match-utils";

const getLocale = () => {
  const locales = {
    "en-US": "en-US",
    "es-ES": "es-ES",
    en: "en-US",
    es: "es-ES",
  };

  const lang = navigator.language;

  // try exact match
  if (typeof locales[lang] !== "undefined") {
    return locales[lang];
  }

  // try country code
  const country = lang.split("-")[0];

  if (typeof locales[country] !== "undefined") {
    return locales[country];
  }

  // default language
  return "en-US";
};

export const AppStateContext = createContext(null);

const AppStateProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const started = useRef(false);
  const { fetchCurrentUser } = useContext(UserContext);
  const { fetchAttributes } = useContext(AttributeContext);
  const { fetchCopy } = useContext(CopyContext);

  useEffect(() => {
    if (started.current) {
      return;
    }
    started.current = true;
    Promise.all([
      themis.initialize(),
      fetchCopy(getLocale()),
      fetchCurrentUser(),
      fetchAttributes({
        categories: ValidAttributeCategories,
        locale: getLocale(),
      }),
    ])
      .catch(err => {
        console.error("Failed to fetch app init data", err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [fetchCopy, fetchCurrentUser, fetchAttributes]);

  return (
    <AppStateContext.Provider
      value={{
        loading,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
