import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { ChatLog as Log } from "@sussex/react-kit/message-center";

import useCopy from "../../hooks/useCopy";

const Bubble = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.chat.received};
  padding: 12px 20px;
  border-radius: 30px 30px 30px 0;

  margin: 15px 50px 35px 0;

  @media (min-width: 900px) {
    margin: 15px 50px 15px 0;
  }

  word-break: break-word;
  a {
    overflow-wrap: break-word;
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
  }
`;

const MessageDate = styled.div`
  position: relative;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0 0;
`;

const CancelWrapper = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;

const RequestDetail = ({ item, setShowCancelConfirm }) => {
  const [
    pendingRequestA,
    pendingRequestB,
    pendingRequestC,
    pendingRequestCancel,
  ] = useCopy([
    "client.pendingRequest.a",
    "client.pendingRequest.b",
    "client.pendingRequest.c",
    "client.pendingRequest.cancel",
  ]);

  const requestMessage = () => {
    const CancelLink = () => (
      <CancelWrapper
        onClick={() => {
          setShowCancelConfirm(true);
        }}
      >
        {pendingRequestCancel}
      </CancelWrapper>
    );

    return (
      <div>
        <p>{pendingRequestA}</p>
        <p>
          {swapCopyVariables(pendingRequestB, {
            CANCEL_LINK: <CancelLink />,
          })}
        </p>
        <p>
          <strong>{pendingRequestC}</strong>
        </p>
      </div>
    );
  };

  if (!item.active) {
    return null;
  }

  return (
    <Log messages={[]} lastReadDate={new Date()}>
      <MessageDate>
        {new Date(item.createdAt * 1000).toLocaleDateString(navigator.locale, {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })}
      </MessageDate>
      <Bubble>{requestMessage()}</Bubble>
    </Log>
  );
};

export default RequestDetail;
