import { useState, createContext } from "react";

export const CopyContext = createContext(null);

export function CopyProvider({ children }) {
  const fetchCopy = async locale => {
    const previewMode = document.cookie.indexOf("sussex-app-preview=1") >= 0;
    const viewingMode = previewMode ? "draft" : "published";
    const [languageCode, countryCode] = locale.split("-");

    try {
      const url = `${
        process.env.REACT_APP_COPY_GROUPS_CDN_URL
      }/client-app/psychologytoday/therapists/${viewingMode}/${countryCode.toLowerCase()}/${languageCode.toLowerCase()}.json`;
      const response = await fetch(url);
      const rawCopy = await response.json();
      const copy = rawCopy.reduce((accum, curr) => {
        accum[curr.key] = curr.value;
        return accum;
      }, {});
      setCopy(copy);
    } catch (err) {
      throw new Error("Failed to fetch copy", err);
    }
  };

  const [copy, setCopy] = useState({});

  return (
    <CopyContext.Provider value={{ copy, fetchCopy }}>
      {children}
    </CopyContext.Provider>
  );
}

export default CopyProvider;
